<template>
  <v-container>
    <v-card elevation="0" v-if="client" class="bg-transparent">
      <v-row class="pb-5 align-center">
        <v-col cols="auto">
          <v-btn
            @click="$router.go(-1)"
            plain
            :ripple="false"
            color="accent"
            small
            outlined
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <h2 class="headline font-weight-medium">
            {{ $t("Client profile") }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" md="6" xl="5" class="pa-0">
          <v-card
            color="primary"
            class="fill-height"
            :class="
              $vuetify.rtl ? 'border-left-radius-0' : 'border-right-radius-0'
            "
            rounded="lg"
            outlined
            elevation="0"
          >
            <div class="pa-lg-10 pa-3 d-flex flex-column">
              <div class="d-flex">
                <UserAvatar :user="client" :size="120" />
                <div class="ms-6 mt-2">
                  <h2 class="white--text">
                    {{ getUserName(client) }}
                  </h2>
                  <p
                    class="white--text font-size-13 font-weight-medium mt-3 mb-0"
                  >
                    <strong class="font-weight-medium">{{
                      $t("Birth date:")
                    }}</strong>
                    {{ birthDate }}
                  </p>
                  <p
                    class="white--text font-size-13 font-weight-normal mt-1 mb-0"
                  >
                    <strong class="font-weight-medium">City:</strong>
                    {{ city }}
                  </p>
                </div>
              </div>
              <h3 class="mt-10 mb-1 subtitle-2 white--text">
                {{ $t("Disorders") }}
              </h3>
              <v-chip-group dark color="primary" dense column>
                <template v-for="disorder in userDisorders">
                  <v-card
                    outlined
                    class="bg-transparent mt-3"
                    flat
                    :key="disorder.name"
                  >
                    <v-card-subtitle class="py-0 mt-n3">
                      {{ $t(disorder.name) }}
                    </v-card-subtitle>
                    <v-card-text class="pb-1">
                      <!--                  <v-chip outlined color="white" small class="font-weight-light mb-2 font-size-13"-->
                      <!--                          :key="disorder.name">-->
                      <!--                    <b>{{ $t(disorder.name) }}</b>-->
                      <!--                  </v-chip>-->

                      <v-chip
                        outlined
                        color="white"
                        small
                        class="font-weight-light mb-2 font-size-13"
                        v-for="selectedVariant in disorder.selectedVariants"
                        :key="selectedVariant"
                      >
                        <b>{{ $t(selectedVariant) }}</b>
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </template>
              </v-chip-group>
              <v-chip
                v-if="getUserDisordersOther(client)"
                outlined
                color="primary"
                small
                class="font-weight-light mb-0 font-size-13"
              >
                <b>{{ getUserDisordersOther(client) }}</b>
              </v-chip>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" xl="7" md="6" class="pa-0">
          <v-card
            rounded="lg"
            class="d-flex flex-column profile-card min-h-100 justify-space-between fill-height px-10"
            :class="
              $vuetify.rtl ? 'border-right-radius-0' : 'border-left-radius-0'
            "
            elevation="0"
            outlined
          >
            <div v-if="client">
              <div
                v-for="(question, i) in client.briefAssessment"
                :key="'q' + i"
              >
                <h3 class="mt-10 mb-1 me-1 subtitle-2">{{ $t(question.q) }}</h3>
                <template v-if="Array.isArray(question.a)">
                  <v-row>
                    <v-col
                      cols="12"
                      md="auto"
                      class="pb-0 font-weight-medium"
                      v-for="(item, i) in question.a"
                      :key="i"
                    >
                      <v-chip
                        color="primary"
                        class="opacity-100 mb-0 font-weight-medium font-size-13"
                        >{{ $t(item.name) }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <h4 class="me-1 opacity-50 subtitle-2 text-capitalize">
                    {{ $t(question.a) }}
                  </h4>
                </template>
              </div>

              <div v-if="teenParentContact" class="mt-11">
                <h5>Parents Contact</h5>

                <h3 class="mt-4 mb-1 me-1 subtitle-2">Parent Name</h3>
                <h4 class="me-1 opacity-50 subtitle-2 text-capitalize">
                  {{ teenParentContact.parentName }}
                </h4>

                <h3 class="mt-4 mb-1 me-1 subtitle-2">Email Address</h3>
                <h4 class="me-1 opacity-50 subtitle-2 text-capitalize">
                  {{ teenParentContact.emailAddress }}
                </h4>

                <h3 class="mt-4 mb-1 me-1 subtitle-2">Phone Number</h3>
                <h4 class="me-1 opacity-50 subtitle-2 text-capitalize">
                  {{ teenParentContact.phoneNumber }}
                </h4>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" class="px-0">
          <v-card elevation="1" outlined rounded="lg" h class="pb-4 px-5 mt-2">
            <v-list>
              <v-list-item class="px-0">
                {{ $t("Subscription Type") }}
                <br />
                <strong class="ms-3 dark--text">
                  {{ $t(getUserSubscriptionPlan(client)) }}
                </strong>
              </v-list-item>
              <v-list-item class="px-0">
                {{ $t("Subscription Duration") }}:
                <br />
                <strong class="ms-3 dark--text"
                  >{{ $t(getUserSubscriptionDuration(client)) }} -
                  {{ getUserSubscriptionEndDate(client) }}</strong
                >
              </v-list-item>
              <v-list-item class="px-0">
                {{ $t("Renewal date") }}:
                <br />
                <strong class="ms-3 dark--text">
                  <template v-if="getUserCancelAtPeriodEnd(client)">-</template>
                  <template v-else>
                    {{ getUserSubscriptionEndDate(client) }}
                  </template>
                </strong>
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item-title class="font-weight-bold mt-4 mb-3">{{
                $t("Live Session Credits:")
              }}</v-list-item-title>
              <v-list-item class="px-0">
                {{ $t("Live Session(s)") }} :<span
                  class="ml-1 body-1 font-weight-medium secondary--text title"
                  >{{ getUserLiveSessionCredits(client) }}</span
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";
import PatientMixin from "@/mixins/PatientMixin";

export default {
  name: "PatientProfile",
  components: { UserAvatar },
  mixins: [UserMixin, PatientMixin],

  computed: {
    client() {
      return this.getClientById(this.$route.params.id);
    },

    birthDate() {
      return this?.client?.profile?.birthDate || "-";
    },

    city() {
      return this?.client?.profile?.location?.city || "-";
    },

    userDisorders() {
      return this.getUserDisordersAndSelectedVariants(this.client);
    },

    teenParentContact() {
      if (this.client.therapyType === "Teen Therapy") {
        return this.client?.alternativeInformation?.teenTerms;
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
